/* eslint no-unused-vars: 0 */

import React, { Fragment, useContext, useEffect, useState } from "react";
import { Message, Divider, Form } from "semantic-ui-react";

import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import StaffService from "../../StaffService";
import StaffForm from "../../components/staff/StaffForm";
import DateTimeService from "../../services/DateTimeService";
import ConfigContext from "../../context/ConfigContext";
import auditDisplayHelper from "../../helpers/auditDisplayHelper";
import GroupService from "../../services/GroupService";
import SubjectService from "../../SubjectService"
import InternationalisationService from "../../InternationalisationService";
import {typeHelper} from "atom5-branching-questionnaire";
import AuthService from "../../services/AuthService";

const StaffCreateAndEditPage = ({ t, match }) => {
  const config = useContext(ConfigContext);

  const staffId = match.params.id;
  const isNew = staffId === undefined;

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const [staff, setStaff] = useState(null);
  const [staffExists, setStaffExists] = useState(true);

  useEffect(() => {
    if (!isNew) {
      const runSetup = async () => {
        try {
          const staff = await StaffService.getStaff(staffId);
          await Promise.all([setStaff(staff), setStaffExists(true)]);
          if (!staff) {
            setErrorMessage(t("STAFF_EMAIL_EXISTS_ERROR_MESSAGE"));
          }
        } catch {
          setStaffExists(false);
        } finally {
          setLoading(false);
        }
      };
      runSetup();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, staffId]);

  const onSubmit = (staff, isEditingOwnAccount) => {
    setErrorMessage(null);
    const args = [
      staff.email,
      staff.firstName,
      staff.lastName,
      staff.drugManager,
      staff.groupRoles,
      staff.superAdmin
    ];

    let createOrUpdateFunction = () => {
      if (!isNew) {
        args.unshift(staffId);
        return StaffService.updateStaff(...args)
      }else{
        return StaffService.createStaff(...args);
      }

    }


    const createGroupIfItDoesntExist = async (groupCode) => {
      try {
        if (groupCode === undefined || groupCode === null || groupCode === "") {
          //no group supplied, do nothing
          return true;
        }

        const response = await GroupService.doesGroupExist(groupCode);
        if (response?.groupCode === 'undefined') {
          await GroupService.newGroup(
              {
                code: groupCode,
                label: groupCode,
                membersLinkable: true,
                alertEmail: null,
                alertLanguage: null,
                labelLanguage: InternationalisationService.getLanguage()
              },
              'Auto generated group'
          );
        }

      } catch(e) {
        console.error("Error saving group", e);
        setErrorMessage("Could not save group, if the problem persists contact support");
        return false;
      }
      return true;
    };

    /**
     * checks if a subject exists, if it doesn't then creates a new subject with the same
     * email address as the staff account
     * @returns the existing or the new subject
     */
    const createSubjectIfItDoesntExist = async (email, groupCode, firstName, lastName) => {
      let subject = await SubjectService.doesSubjectExistWithEmail(email);

      //subject doesn't exist, create it
      if (subject?.Id === undefined) {
        subject = await SubjectService.createSubject(
            {groupCodes: groupCode},
            {subjectdetailspersonal_name: `${firstName} ${lastName}`},
            "",
            {email: email});
      }
      return subject;
    };

    return createOrUpdateFunction()
        .then(async (result) => {
          //after the creation of the staff member check if create linked account is ticket
          //if it is then do the following:
          if (staff.createLinkedAccountCheckbox) {

            const linkingEnabled = await StaffService.isStaffToSubjectLinkingEnabled();
            if (!typeHelper.parseBool(linkingEnabled.enabled)) {
              if (isEditingOwnAccount) {
                await AuthService.logout();
                AuthService.setAccountType("staff");
                return;
              }
              window.location.href = window.location.origin + "/app/staff";
              return;
            }

            //create the group if it doesn't exist
            if (!await createGroupIfItDoesntExist(staff.subjectGroup)) {
              //error, dont proceed
              return;
            }

            //group exists/has been created
            //next, check if subject already exists, if it does then link, if subject doesn't exist then create a new subject
            await createSubjectIfItDoesntExist(staff.email, staff.subjectGroup, staff.firstName, staff.lastName);

            //need to link the staff member and the subject
            await StaffService.linkStaffToSubjectOnEmail(staff.email);
          }

          if (isEditingOwnAccount) {
            await AuthService.logout();
            AuthService.setAccountType("staff");
            return;
          }
          window.location.href = window.location.origin + "/app/staff";
        })
        .catch(([e]) => {
          handleError(e);
        });
  };

  const handleError = (e) => {
    let messageText;
    
    if (e?.message?.indexOf("Email exists") >= 0) {
      messageText = t("STAFF_EMAIL_EXISTS_ERROR_MESSAGE");
    } else if (e?.message?.indexOf("Must include at least one group") >= 0) {
      messageText = t("STAFF_NO_GROUP_ROLES_ERROR_MESSAGE", "Please enter one or more site roles for this staff member");
    } else if (e?.message?.indexOf("All groups must include at least one role") >= 0) {
      messageText = t("STAFF_NO_ROLES_IN_GROUP_ERROR_MESSAGE", "All selected sites must have at least one role");
    } else if (e?.message?.indexOf("Invalid email address") >= 0) {
      messageText = t('STAFF_INVALID_EMAIL_ERROR_MESSAGE', 'Invalid Email Address');
    }
    if (!messageText) {
      messageText = t(
          "GLOBAL_ERROR_GENERIC_FORM_MESSAGE",
          "Please fill in all the required fields"
      );
    }
    setErrorMessage(messageText);
  };

  const auditLastUpdated = () => {
    if (!isNew) {
      if (staff && staff.auditLastUpdated) {
        return DateTimeService.build.asDisplayDateTime(staff.auditLastUpdated);
      }
    }
    return "";
  };

  const auditLastUpdatedBy = () => {
    return auditDisplayHelper.getAuditDisplayForStaff(staff, config);
  };

  const pageName = isNew ? "STAFF_CREATE" : "STAFF_EDIT";
  const pageHeader = isNew ? "STAFF_CREATE_HEADER" : t("STAFF_EDIT_HEADER");
  const pageSubHeader = isNew
      ? "STAFF_CREATE_SUBHEADER"
      : "STAFF_EDIT_SUBHEADER";

  return (
      <Page
          name={pageName}
          header={t(pageHeader)}
          subheader={t(pageSubHeader)}
          loading={loading}
      >
        {staffExists && (
            <Fragment>
              <StaffForm onSubmit={onSubmit} staff={staff} error={errorMessage} />
              {!isNew && (
                  <>
                    <Divider />
                    <Form noValidate>
                      <Form.Input
                          label={t("AUDIT_LAST_UPDATED_DATE")}
                          name="auditLastUpdated"
                          id="auditLastUpdated"
                          value={auditLastUpdated()}
                          disabled
                      />
                      <Form.Input
                          label={t("AUDIT_LAST_UPDATED_BY")}
                          name="auditLastUpdatedBy"
                          id="auditLastUpdatedBy"
                          value={auditLastUpdatedBy()}
                          disabled
                      />
                    </Form>
                  </>
              )}
            </Fragment>
        )}

        {!staffExists && (
            <Message error>
              <Message.Header>{t("STAFF_FORM_ERROR_TITLE")}</Message.Header>
              {t("STAFF_NOT_FOUND_ERROR_MESSAGE")}
            </Message>
        )}
      </Page>
  );
};

export default withTranslation()(StaffCreateAndEditPage);
